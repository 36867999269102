.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(
      650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    );
}
.btn-custom {
  background-color: #018673;
}
.img-custom {
  width: 90%;
}
#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}
section,
.container {
  height: 100vh;
}

/* rainbow buttons  */
.mybtn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  font-size: 14px;
  padding: 25px 45px;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  border-radius: 500px;
  font-weight: bold;
  font-family: Arial;
  background: linear-gradient(
    90deg,
    #ff6f61,
    #ff8777,
    #ff98a0,
    #ffabda,
    #d7a2f5,
    #d7a2f5,
    #7ca5de,
    #3e73bd
  );
  background-size: 1800% 100%;
  -webkit-animation: rainbow 5s linear infinite;
  animation: rainbow 5s linear infinite;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes rainbow {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
